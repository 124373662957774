import { TurnOrder } from 'boardgame.io/core';
import { INVALID_MOVE } from 'boardgame.io/core';
import { initGameStat, initPlayerData } from './DataGame';
import { rollDice, finishDice, stepMasu, finishStep, doEvent,finishKessan,done } from './MoveDice';
import { MASU_DATA } from './DataMasu';

/******************************** ゲーム定義 ***************************************/
const SugorokuGame = {
  name: 'SugorokuGame',
  minPlayers: 1,
  maxPlayers: 4,
  setup: ({ ctx }, setupData) => {
    console.log("Game setup");
    console.log(setupData);
    console.log(ctx);

    const players = Array(ctx.numPlayers).fill().map((_, i) => initPlayerData(i));
    const gameStat = initGameStat();

    if(!setupData){
      //SetupDataがない場合はAI戦として判断
      gameStat.isAI = true;
    }

    return { players, gameStat };
  },
  moves: {
    // サイコロを振る(内部)
    rollDice: rollDice,
    // サイコロUI終了
    finishDice:finishDice,
    // マス移動
    stepMasu: stepMasu,
    // 移動修了
    finishStep: finishStep,
    // 決算終了
    finishKessan: finishKessan,
    // イベントを実行
    doEvent: doEvent,
    // ターンを終了する
    done: done,

    // AIのタイミング制御をStageで対応するようにしたので没
    // sleepStart: ({G, ctx},waitMsec,nextFunc) => {
    //   const date = new Date();
    //   //Date型はGに格納するとエラーが出るので文字列型に変換してから格納
    //   G.gameStat.sleepStart = date.toISOString();
    //   G.gameStat.sleepMsec = waitMsec;
    //   G.gameStat.sleepnextFunc = nextFunc;
    // },
    // waitUntil: ({G, ctx, events, playerID}) => {//指定時間待機
    //   const player = G.players[playerID];
    //   // sleepStartをDate型に戻す
    //   const startDate = new Date(G.gameStat.sleepStart);

    //   if(new Date() - startDate > G.gameStat.sleepMsec){
    //     G.gameStat.sleepMsec = 0;
    //     G.gameStat.sleepStart = 0;
    //     if(G.gameStat.sleepnextFunc == "finishDice"){
    //       finishDice({G, playerID});
    //     }else if(G.gameStat.sleepnextFunc == "step"){
    //       stepMasu({G, playerID});
    //       if(player.positionDisplay == player.position){
    //         finishStep({G});
    //       }
    //     }else if(G.gameStat.sleepnextFunc == "kessan"){
    //       finishKessan({G, playerID});
    //     }else if(G.gameStat.sleepnextFunc == "event"){
    //       console.log("doevent")
    //       doEvent({G, ctx, playerID});
    //     }else if(G.gameStat.sleepnextFunc == "done"){
    //       done({G, events,playerID});
    //     }
    //     G.gameStat.sleepnextFunc = "";
    //   }
    // }
  },
  turn: {
    stages: {
      aiStage: {
          moves: {
            // サイコロUI終了
            finishDice:finishDice,
            // マス移動
            stepMasu: stepMasu,
            // 移動修了
            finishStep: finishStep,
            // 決算終了
            finishKessan: finishKessan,
            // イベントを実行
            doEvent: doEvent,
            // ターンを終了する
            done: done,
            na: ()=>{}
          },
      },
    },
    onBegin: ({G, ctx}) => {
      G.gameStat.diceStep="dice";
    },
    onEnd: ({G, ctx}) => {
      G.gameStat.diceStep="";
    },
  },

  /**
   * ゲーム終了
   * @param {*} param0
   * @returns
   */
  endIf: ({ G, ctx }) => {
    // 全プレイヤーがゴールに到達したら終了
    const isEnd = G.players.every(p => p.goal);
    if (isEnd) {
      return { winner: null,reason:"全プレイヤーがゴールに到達" };
    }
  },

  //BOTの定義
  ai: {
    enumerate: (G, ctx) => {
      let moves = [];
      if(G.gameStat.diceStep == "dice"){
        moves.push({ move: 'rollDice'});
      }else{
        moves.push({ move: 'na'})
      }
      return moves;
    },
  },
};

export { SugorokuGame };
