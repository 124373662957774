import React from 'react';
import { Sprite, useTick } from '@pixi/react';
import { lerp } from './PixiUtil';

export const SpriteKoma = ({...props}) =>{
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage, x, y, screen_scale, koma_id } = props;

  const i = React.useRef(0);
  const [rotate, setRotate] = React.useState(0);
  const [position, setPosition] = React.useState({x:x,y:y});

  // マップ移動をアニメーションにするための処理
  useTick((delta) => {
    // 現在の位置と目標位置の間で線形補間を行い、位置を更新
    const newX = lerp(position.x, x, 0.1 * delta);
    const newY = lerp(position.y, y, 0.1 * delta);
    setPosition({ x: newX, y: newY });
  });
  useTick(() => {
    setRotate(Math.sin(i.current++ * 0.05) * 0.5);
  });

  // プレイヤー人数によって表示位置を変える
  const padding = 12 * screen_scale;//プレイヤー幅
  const left = (padding/2) * (G.players.length-1) ;

  return (
    <Sprite
      x={position.x*screen_scale - left + (koma_id * padding)}
      y={position.y*screen_scale}
      anchor={0.5}
      rotation={rotate}
      width={56*screen_scale}
      height={88*screen_scale}
      image={`image/koma_${koma_id+1}.png`}
    />
  );
};