import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Sprite, Container, Text, useApp, useTick, Graphics } from '@pixi/react';
import * as PIXI from 'pixi.js';
import { Texture, TextStyle } from 'pixi.js';
import { easeOutBounceStrong, easeOutBounceWeak} from './PixiUtil';
import { getPlayerMasu } from '../util';
import { ContainerDialog } from './ContainerDialog';
import { getGoalMoney } from '../util';

const ContainerEvent = ({...props}) => {
  const { G, playerID,ctx, x, y, width, height, children, screen_scale, map_scale, onClick, masu } = props;

  const videoRef = useRef(null);
  const fontSize = 24 * screen_scale;
 
  const pid = ctx.currentPlayer;
  const player = G.players[pid];

  const effectText = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false); //動画再生状態

  const defaultImg = {
    "money_p": "event_default_money_p.png",
    "money_n": "event_default_money_n.png",
    "move_p": "event_default_move_p.png",
    "move_n": "event_default_move_n.png",
    "power_p": "event_default_power_p.png",
    "power_n": "event_default_power_n.png",
  }
  

  var positiveEffect = true;
  var kindEffect = "money_p";

  if(masu.effectType === "move"){
    if(masu.effectValue > 0){
      effectText.current = masu.effectValue + "マス進む";
      positiveEffect = true;
      kindEffect = "move_p";
    }else{
      effectText.current = masu.effectValue + "マス戻る";
      positiveEffect = false;
      kindEffect = "move_n";
    }
  }else if(masu.effectType === "money"){
    if(masu.effectValue > 0){
      effectText.current = masu.effectValue + "の資金を得た";
      positiveEffect = true;
      kindEffect = "money_p";
    }else{
      effectText.current = masu.effectValue + "の資金を失った";
      positiveEffect = false;
      kindEffect = "money_n";
    }
  }else if(masu.effectType === "power"){
    if(masu.effectValue > 0){
      effectText.current = masu.effectValue + "の企業力UP！";
      positiveEffect = true;
      kindEffect = "power_p";
    }else{
      effectText.current = masu.effectValue + "の企業力Down...";
      positiveEffect = false;
      kindEffect = "power_n";
    }
  }else if(masu.effectType === "goal"){
    effectText.current = `${player.goalOrder}位\n${getGoalMoney(player.goalOrder,player.cpPower)}を獲得!`;
    positiveEffect = true;
  }

  // masu.descriptionが26文字を超えている場合は\nを挿入
  var description = masu.description;
  if(masu.description.length > 26){
    description = masu.description.slice(0, 26) + "\n" + masu.description.slice(26);
  }
  const media_x = 224*screen_scale;
  const media_y = 20*screen_scale;

  // イベント領域要素
  const eventSprite = ({  }) => {
    // 動画が設定されている場合
    if(masu.movie){
      let mediaTexture = null;
        if(!isPlaying){//再生ボタン表示
            return(
                <Sprite 
                key={masu.eventImg}
                image={"image/play.png"}
                width={width/2}
                height={height/2}
                x={media_x}
                y={media_y}
                interactive={true}
                //ポインタフォーカス時に明るくする
                pointerover={(e) => e.target.alpha = 0.8}
                pointerout={(e) => e.currentTarget.alpha = 1.0}

                pointerdown={(e) => {
                  setIsPlaying(true);
                  //再生ボタンをクリック時は親のonClickを無効にする
                  e.stopPropagation();
                }}
              />
            )
        }else{ //再生中
          // refs:https://gamedev.stackexchange.com/questions/182805/how-to-get-set-specific-properties-of-a-video-texture-in-pixi-js
          // refs:https://pixijs.com/8.x/examples/sprite/video
          mediaTexture = PIXI.Texture.from(masu.movie);
          mediaTexture.baseTexture.resource.source.volume = 0.1;
          mediaTexture.baseTexture.resource.source.play();

          return(
            <Sprite // イベント動画
              key={crypto.randomUUID()}//キャッシュ対策でランダム文字列を付与
              texture={mediaTexture}//MEMO:Textureで指定する場合
              // image={masu.movie} //MEMO:Textureを使わない場合
              width={width/2}
              height={height/2}
              x={media_x}
              y={media_y}
              ref={videoRef}
              interactive={true}
              pointerdown={(e) => {
                setIsPlaying(false);
                mediaTexture.baseTexture.resource.source.pause();

                //再生ボタンをクリック時は親のonClickを無効にする
                e.stopPropagation();
              }}
            />
          )
        }
    
    // イベント画像が設定されている場合
    }else if(masu.movie === "" && masu.eventImg){// イベント画像
      return(
        <Sprite 
          key={masu.eventImg}
          image={"image/"+masu.eventImg}
          width={width/2}
          height={height/2}
          x={media_x}
          y={media_y}
        />
      )
    
    // イベント画像も動画も設定されていない場合
    }else if(masu.movie === "" && masu.eventImg ===""){
      return(
        <Sprite // イベント画像
          key={positiveEffect?"p":"n"}
          image={"image/"+defaultImg[kindEffect]} // 画像のURL
          width={width/2}
          height={height/2}
          x={media_x}
          y={media_y}
        />
      )
    }
  }

  return(
      <ContainerDialog
        {...props}
      >
        <Sprite // イベント背景画像
          image={positiveEffect?"image/frame_good.png":"image/frame_bad.png"} // 画像のURL
          width={width}
          height={height}
          x={0}
          y={0}
        />

        {/* イベント領域要素 */
          eventSprite({})
        }
        
        {/* テキスト下地 */}
        <Sprite
          image="image/textbox_event.png"
          x={75*screen_scale}
          y={310*screen_scale}
          width={810*screen_scale}
          height={215*screen_scale}
          />

        <Text text={effectText.current}
          x={width/2}
          y={height*(9/10)}
          anchor={0.5}
          style={{fill: 0xffffff, fontSize: fontSize}}/>

        <Text
          text={description} 
          x={width/2}
          y={height/2+120*screen_scale}
          anchor={0.5}
          style={
            new TextStyle({
              align: 'center',
              // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
              fontSize: fontSize*screen_scale,
              fontWeight: '400',
              lineHeight:fontSize/1.5, //行間
              // fill: ['#ffffff', '#00ff99'], // グラデーション
              fill: '#ffffff',
              stroke: '#01d27e',
              strokeThickness: 5,
              // letterSpacing: 20,
              dropShadow: true,
              dropShadowColor: '#ccced2',
              dropShadowBlur: 4,
              dropShadowAngle: Math.PI / 6,
              dropShadowDistance: 6,
              wordWrap: true,
              wordWrapWidth: 440,
          })}
          />


      </ContainerDialog>
  );
};

export { ContainerEvent };