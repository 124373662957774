import React, { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text, useApp, useTick } from '@pixi/react';
import { Texture } from 'pixi.js';
import { lerp } from './PixiUtil';
import { getPlayerMasu, getPlayerName } from '../util';

const ContainerPlayerStat = ({...props}) => {
  const { G, playerID, ctx, matchData, x, y, width, height, children, screen_scale, map_scale, onClick } = props;

	const fontSize = 18 * screen_scale;
	const fontStyle = {
		fontFamily: 'Arial',
		fontSize: fontSize,
		fill: 0xffffff,
		align: 'left',
		stroke: 0x000000,
		strokeThickness: 4,
		lineJoin: 'round',
	};

	return(
		<Container
			x={0}
			y={0} 
		>
			{/* プレイヤーのステータス表示 */
				G.players.map((player, index) => {
					//AI戦の場合はプレイヤー1のステータスは表示しない
					// if(G.gameStat.isAI && player.id === 1) return null;

					return (
						<Container
							key={player.id}
							x={20*screen_scale}
							y={(20 + index * 72)*screen_scale} 
						>
							<Sprite
								image={`image/waku_status_thumbnail_${index+1}.png`}
								x={0}
								y={0}
								width={64*screen_scale}
								height={64*screen_scale}
							/>
							<Sprite
								image={`image/waku_status_migiue_${index+1}.png`}
								x={64*screen_scale}
								y={0}
								width={240*screen_scale}
								height={32*screen_scale}
							/>
							<Sprite
								image={`image/waku_status_migishita_${index+1}.png`}
								x={64*screen_scale}
								y={32*screen_scale}
								width={240*screen_scale}
								height={32*screen_scale}
							/>
							<Text
								text={`資金 　　　${player.money}万円`}
								x={(64+2)*screen_scale}
								y={2}
								style={fontStyle}
							/>
							<Text
								text={`利益　${player.cpPower*100}万　株価　${player.kabu}株`}
								x={(64+2)*screen_scale}
								y={34*screen_scale}
								style={fontStyle}
							/>
						</Container>
					);
				})
			}
		</Container>
  );
};

export { ContainerPlayerStat };