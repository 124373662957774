import React from 'react';
import { Sprite, withFilters, Container, Text } from '@pixi/react';
import * as PIXI from 'pixi.js';

export const ContainerMasu = ({...props}) =>{
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage, x, y, screen_scale, masu, onClick } = props;
  const player = G.players[ctx.currentPlayer]
  // 既に訪れたマスかどうか
  // const isVisit = G.players[ctx.currentPlayer].positionDisplay >= masu.id;
  // visitMasu[]に含まれているかどうか
  const isVisit = G.gameStat.visitMasu.includes(masu.id);
  // フェーズがdiceかつマスの位置がプレイヤーの位置より後ろの場合クリック可能。ゲームクリア後もクリック可能
  const canInteract = masu.effectType !== "kessan" 
                      && 
                      (
                        (G.gameStat.diceStep == "dice"  && isVisit)
                        || (ctx.gameover != undefined && masu.id !=0)
                      );
  const Filters = withFilters(Container, {
    color: PIXI.filters.ColorMatrixFilter,
  });

  // グレースケールフィルター
  const grayscale_matrix = [
    0.2,0.2,0.2,  0,  0,
    0.2,0.2,0.2,  0,  0,
    0.2,0.2,0.2,  0,  0,
      0,  0,  0,1.0,  0,
  ]
  const normal_matrix = [
    1.0,  0,  0,  0,  0,
      0,1.0,  0,  0,  0,
      0,  0,1.0,  0,  0,
      0,  0,  0,1.0,  0,
  ]
	const fontSize = 20 * screen_scale;
	const fontStyle = {
		fontFamily: 'Arial',
		fontSize: fontSize,
		fill: 0x000000,
		align: 'center',
		stroke: 0xffffff,
		strokeThickness: 5,
		lineJoin: 'round',
	};
	const fontStyleMada = {
		fontFamily: 'Arial',
		fontSize: fontSize,
		fill: 0x333333,
		align: 'center',
		stroke: 0xaaaaaa,
		strokeThickness: 5,
		lineJoin: 'round',
	};
  // マスのタイトルを5文字ごとに\nで改行
  const title = masu.title ? masu.title.match(/.{1,5}/g).join("\n") : "";

  const sizeW ={
    "n":masu.w*screen_scale,
    "b":masu.w*1.1*screen_scale
  }
  const sizeH ={
    "n":masu.h*screen_scale,
    "b":masu.h*1.1*screen_scale
  }

  const [masuw, setMasuW] = React.useState(sizeW["n"]);
  const [masuh, setMasuH] = React.useState(sizeH["n"]);

  //screen_scaleが変化した場合masuw,masuhを変更
  React.useEffect(()=>{
    setMasuW(sizeW["n"]);
    setMasuH(sizeH["n"]);
  },[screen_scale]);

  return (
    <Container
      x={(masu.x/*+masuw/2*/)*screen_scale}
      y={(masu.y/*+masuh/2*/)*screen_scale}
      interactive={canInteract}
      cursor={canInteract?"pointer":null}
      pointerdown={()=>{
        if(onClick){
          onClick(masu.id);
        }
      }}
      pointerover={(e)=>{setMasuW(sizeW["b"]); setMasuH(sizeH["b"]);}}
      pointerout={(e)=>{setMasuW(sizeW["n"]); setMasuH(sizeH["n"]);}}
      >
      <Filters color={{matrix:isVisit?normal_matrix:grayscale_matrix}}>
        <Sprite
          image={`image/${masu.img}`}
          // image={`https://pixijs.com/assets/video.mp4`}
          width={masuw}
          height={masuh}
          anchor={0.5}
        />
      </Filters>
      <Text
          text={title}
          anchor={0.5}
          z={1000}
          style={canInteract?fontStyle:fontStyleMada}
        />
    </Container>
  );
};