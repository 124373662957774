import React from 'react';
import { GameLobby } from './lobby/Lobby';
import { SugorokuGame } from './sugoroku/game/SugorokuGame';
import { SugorokuBoard, SugorokuClient } from './sugoroku/SugorokuBoard';
// import { Lobby } from 'boardgame.io/react';
import {DEBUG, GAME_SERVER, LOBBY_SERVER, MULTI_GAME} from './setting';

import './App.css';
// import JoinKey from './lobby/JoinKey';

const App = () => {
  // const [join, setJoin] = React.useState(null);
  // //認証前
  // if(join===null || 
  //   //promiseが解決されていない
  //   (join instanceof Promise && join._state===0) ){
  //   return (
  //     //参加コードを入力してください
  //     <JoinKey setJoin={setJoin}/>
  //   )
  // //認証済み
  // }else
  {
    if(MULTI_GAME){
      return (
      <GameLobby
        gameServer={ GAME_SERVER }
        lobbyServer={ LOBBY_SERVER }
        // 認証情報からサーバー情報を取得する
        // gameServer={ DEBUG?GAME_SERVER:   join.url }
        // lobbyServer={ DEBUG?LOBBY_SERVER: join.url }
          gameComponents={[
          // { game: GX, board: GXBoard },
          { game: SugorokuGame, board: SugorokuBoard }
        ]}
        debug={DEBUG}
        //grname={join.name}
        grname={"test"}
      />
      )
    }else{ //一人用
      return (
        <SugorokuClient playerID='0'></SugorokuClient>
      )
    }
  }
};

export default App;
