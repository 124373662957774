import { MASU_DATA } from "./game/DataMasu";

function getPlayerName(id,matchData){
  if(!matchData){ //ローカルプレイの場合matchDataがないため決め打ち
    return id==0?"Player":"AI";
  }
  for( const m of matchData){
    if(m.id == id){
      return m.name? m.name : "Player"+(id+1);
    }
  }
  return "Player"+(id+1);
}

function getPlayerColor(id){
  const colors = [
    'rgb(255, 99, 132)',
    'rgb(53, 162, 235)',
    'rgb(75, 192, 192)',
    'rgb(252, 206, 100)'
  ];
  return colors[id];
}

function getPlayerMasu(G, playerID){
  return MASU_DATA[G.players[playerID].positionDisplay];
}
function getIdxMasu(position){
  return MASU_DATA[position];
}

// ゴール順位に応じた賞金額
function getGoalMoney(order,cpPower){
  const money = [500,300,200,100];
  return money[order] * cpPower;
}

export {getPlayerName, getPlayerColor, getPlayerMasu,getIdxMasu,getGoalMoney};