import React, { useEffect, useRef } from 'react';
import { Sprite, Container, useApp, useTick} from '@pixi/react';
import * as PIXI from 'pixi.js';
import { Texture , Point} from 'pixi.js';

import { lerp } from './PixiUtil';
import { MASU_DATA } from '../game/DataMasu';
import { SpriteKoma } from './SpriteKoma';
import { SpriteBackground } from './SpriteBackground';
import { ContainerMasu } from './ContainerMasu';
import { getPlayerMasu } from '../util';
import { finishStep } from '../game/MoveDice';

import { PixiViewport } from './PixiViewport';
import { ContainerMasuMoji } from './ContainerMasuMoji';

const ContainerMap = ({...props}) => {
  const { G, playerID, moves, ctx, width, height, children, screen_scale, masuClick, isZoom } = props;
  //AI戦の場合はplayerIDをそのまま使う。AIでない場合はプレイ中のプレイヤー
  // const pid = G.gameStat.isAI? playerID: ctx.currentPlayer;
  const pid = ctx.currentPlayer;
  const focusPlayer = G.players[pid];
  const focusMasu = getPlayerMasu(G, pid);
  const focusX = focusMasu.x;
  const focusY = focusMasu.y;
  const [position, setPosition] = React.useState({x:focusX,y:focusY});
  const [komaAnimation, setKomaAnimation] = React.useState(false); //アニメーション中かどうか
  const [viewportScale, setViewportScale] = React.useState(1); //Viewport拡大率初期値
  const [viewportTop, setViewportTop] = React.useState(position.y*screen_scale - height/(2*viewportScale)); //Viewportのtop初期値
  const [viewportLeft, setViewportLeft] = React.useState(position.x*screen_scale - width/(2*viewportScale)); //Viewportのleft初期値
  const [mapZoomAnimation, setMapZoomAnimation] = React.useState(true); //マップズームアニメーション中かどうか
  const zoomSet = isZoom?
    {scale:1, top:position.y*screen_scale - height/(2*viewportScale), left:position.x*screen_scale - width/(2*viewportScale)}
    :{scale:0.5, top:0, left:0};
  const app = useApp();
  const viewportRef = useRef();
  PixiViewport.displayName = 'PixiViewport';

  // G.gameStat.diceStep の変化を監視し、変化があれば animation を true に設定
  useEffect(() => {
    if (G.gameStat.diceStep === "dice" || G.gameStat.diceStep === "step" || G.gameStat.diceStep === "end") {
      setKomaAnimation(true);
    }
  }, [G.gameStat.diceStep]);  // この配列内の G.gameStat.diceStep が変化するたびに useEffect 内のコードが実行される

  // Viewportのスケール変化を監視し、変化があればアニメーションを開始
  useEffect(() => {
    setMapZoomAnimation(true);
  }, [isZoom]);

  // アニメーション
  useTick((delta) => {
    // プレイヤーコマのアニメーション
    if( komaAnimation ){
      // 現在の位置と目標位置の間で線形補間を行い、位置を更新
      const newX = lerp(position.x, focusX, 0.1 * delta);
      const newY = lerp(position.y, focusY, 0.1 * delta);
      if(Math.round(focusX) != Math.round(newX) || Math.round(focusY) != Math.round(newY)){

        // console.log("komaAnimation");
        setPosition({x:newX, y:newY});
        setViewportTop(zoomSet.top);
        setViewportLeft(zoomSet.left);

      //見た目の位置が一致
      // if(Math.round(focusX) == Math.round(newX) && Math.round(focusY) == Math.round(newY)){
      }else{
        if(G.gameStat.diceStep === "kessan"){
          //決算に入った場合はいったん止まる
          return;
        }else if(focusPlayer.positionDisplay != focusPlayer.position){
          // マス移動表示を進める
          // if(ctx.currentPlayer === playerID){
            if(G.gameStat.diceStep == "step"
            || G.gameStat.diceStep == "end"/* マスの効果で動くとき */){
                  moves.stepMasu(G, ctx, pid);
            }
          // }
        }else{
        // 表示上の位置と実際の位置が一致したら次のステップへ
          setKomaAnimation(false);
          if(G.gameStat.diceStep === "step"){
            moves.finishStep(G);
          }
          if(G.gameStat.diceStep === "end"){
            moves.done();
          }
        }
      }
    }

    // マップズームアニメーション
    if (mapZoomAnimation) {
      // console.log("mapZoomAnimation");
      const animeRate = 0.1 * delta;
      // 現在のスケールと目標スケールの間で線形補間を行い、スケールを更新
      const newScale = lerp(viewportScale, zoomSet.scale, animeRate);
      const newTop = lerp(viewportTop, zoomSet.top, animeRate);
      const newLeft = lerp(viewportLeft, zoomSet.left, animeRate);
      setViewportScale(newScale);
      setViewportTop(newTop);
      setViewportLeft(newLeft);
      // スケールが目標に近づいたらアニメーションを停止
      if (Math.abs(zoomSet.scale - viewportScale) < 0.01) {
        setMapZoomAnimation(false);
      }
    }
  });


  //引数に変更
  // // Viewportの拡大率
  // var viewportScaled = 2;//初期値
  // const viewport = viewportRef.current;
  // if(viewport){
  //   viewportScaled = viewport.scaled;
  // }
  return(
    <PixiViewport
      ref={viewportRef}
      app={app} // appインスタンスをPixiViewportに渡す
      width={app.screen.width}
      height={app.screen.height}
      worldWidth={app.screen.width} // ここに実際のワールドサイズを設定
      worldHeight={app.screen.height} // ここに実際のワールドサイズを設定
      scaled={viewportScale}
      // top={position.y-height/2}
      // left={position.x-width/2}
      // top={position.y*screen_scale*viewportScaled - height/2}
      // left={position.x*screen_scale*viewportScaled - width/2}
      top={viewportTop}
      left={viewportLeft}
      // top={0}
      // left={0}
      // center={new Point(
      //   position.x*screen_scale*viewportScaled,
      //   position.y*screen_scale*viewportScaled
      //   )}
      // right={width}
      // bottom={height}

      plugins={[
        "drag", 
        // "pinch",
        // "wheel",
        // "decelerate"
      ]}
    >
      {/* 背景*/}
      <SpriteBackground
        {...props}
        image="image/tile.png"
        x={-width}
        y={-height}
        width={width*4}
        height={height*4}   
        /> 
      <Container
        x={0}
        y={0}
        // Mapを動かさずにViewportを動かす
        // x={(-position.x*screen_scale*map_scale + width/2)}
        // y={(-position.y*screen_scale*map_scale + height/2)}
      >
        <Sprite
          image="image/map.png" // 画像のURL
          width={width*2} // 幅
          height={height*2} // 高さ
          x={0}
          y={0}
        />
        { //マス画像
          MASU_DATA.map((masu, index) => {
            return (
              <ContainerMasu
                {...props}
                key={index}
                masu={masu}
                onClick={masuClick}
                //プレイヤー位置より先のマスは色を黒くする
                filter={focusPlayer.position >= masu.id ? null : new PIXI.filters.ColorMatrixFilter()}
              />
            );
          })
        }
        { // マス文字(sortableChildrenとzIndexは直下の子にしか作用しないため苦肉の策で別ループ)
          MASU_DATA.map((masu, index) => {
            return (
              <ContainerMasuMoji
                {...props}
                key={index}
                masu={masu}
                onClick={masuClick}
              />
            );
          })
        }
        { //プレイヤーコマ画像
          G.players.map((p, index) => {
          const masu = getPlayerMasu(G, p.id);
          // if (G.gameStat.isAI && p.id === 1) {
          //   return null;
          // }

          return (
            <SpriteKoma
              {...props}
              key={index}
              x={masu.x}
              y={masu.y}
              screen_scale={screen_scale}
              koma_id={p.id}
            />
          );
        })}
      </Container>
    </PixiViewport>
  );
};

export { ContainerMap };