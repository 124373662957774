import React, { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text, useApp, useTick } from '@pixi/react';
import { Texture } from 'pixi.js';
import { lerp } from './PixiUtil';
import { getPlayerMasu, getPlayerName } from '../util';
import ContainerRoulette from './ContainerRoulette';

const ContainerActionDice = ({...props}) => {
const { G, ctx, moves, playerID, matchData, x, y, width, height, children, screen_scale, map_scale, onClick } = props;
	const player = G.players[ctx.currentPlayer];

	const size = 150 * screen_scale;
	const dice_w = 215*screen_scale;
	const dice_h = 75*screen_scale;
	const [state, setState] = useState("init");//init:初期状態, start:ルーレット回転, stop:ルーレット停止,
	const clickStartRoulette = () => {
		moves.rollDice();
	}
	const rouletteStop = () => {
		setState("stop");
		if(G.gameStat.isAI && "1" == ctx.currentPlayer){
			//1秒後に自動で終了
			setTimeout(()=>{moves.finishDice();},1000);
		}
	}
	const clickConfirm = () => {
		moves.finishDice();
		setState("end");
	}
	//G.gameStat.diceStepが"dice"の時にstateを更新
	useEffect(() => {
		if(G.gameStat.diceStep === "dice"){
			setState("init");
		}
	}, [G.gameStat.diceStep]);

return(
	<Container
		x={0} 
		y={0}
		>
		{ // 自分の手番の時のみかつサイコロが回転していない時に開始ボタンを表示
			playerID == ctx.currentPlayer && state === "init" &&
			<Sprite
				image="image/action_dice.png" // 画像のURL
				x={30*screen_scale}
				y={365*screen_scale}
				// anchor={0.5}
				width={dice_w} // 幅
				height={dice_h} // 高さ
				interactive={true}
				cursor='pointer'
				pointerdown={clickStartRoulette}
				//マウスフォーカス時に画像効果を変更
				pointerover={(e)=>{e.target.width = dice_w*1.1;e.target.height = dice_h*1.1;}}
				pointerout={(e)=>{e.target.width = dice_w;e.target.height = dice_h;}}
				/>
		}
		{ //ルーレットコンテナ
			player.dice !=0 && G.gameStat.diceStep == "donedice" && (state==="init"||state==="stop") && (
				<ContainerRoulette
					{...props}
					x={width/2}
					y={height/2}
					width={height}
					height={height}
					targetNumber={player.dice}
					onStop={()=>{rouletteStop();}}
					onClick={()=>{clickConfirm();}}
					/>

			)

		}
		{ // 自分の手番の時のみかつルーレットが停止している時にOKボタンを表示
			playerID == ctx.currentPlayer && state === "stop" &&
			<Sprite
				image="image/action_confirm.png" // 画像のURL
				x={width - size*1.2/2}
				y={height - size*1.2/2}
				anchor={0.5}
				width={size} // 幅
				height={size} // 高さ
				interactive={true}
				cursor='pointer'
				pointerdown={()=>{clickConfirm();}}
				//マウスフォーカス時に画像効果を変更
				pointerover={(e)=>{e.target.width = size*1.2;e.target.height = size*1.2;}}
				pointerout={(e)=>{e.target.width = size;e.target.height = size;}}
				/>
		}

	</Container>
);
};

export { ContainerActionDice };