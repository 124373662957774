// ContainerGoal.jsx
import React, { useState } from 'react';
import { Sprite, Container, useTick } from '@pixi/react';
import { easeOutBounceStrong } from './PixiUtil';
import { ContainerDialog } from './ContainerDialog';

const ContainerGoal = ({ ...props }) => {
  const { G, playerID, x, y, width, height, children, screen_scale, map_scale, onClick, masu, image } = props;

  return (
    <ContainerDialog
      {...props}
      speed={0.01}
    >

      <Sprite //ゴール表示
        image={image} 
        width={width}
        height={height}
        x={0}
        y={0}
        />
    </ContainerDialog>
  );
};

export { ContainerGoal };
