import {
  TilingSprite,
  useTick
  } from '@pixi/react';
import React from 'react';
export const SpriteBackground = ({...props}) =>{
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage, width, height, x=0, y=0, screen_scale, map_scale ,image, isMove=false, isBounce=false} = props;

  const [count, setCount] = React.useState(0);
  const [state, setState] = React.useState({
      tileScale: { x: 1, y: 1 },
      tilePosition: { x: 0, y: 0 }
    });

  useTick((delta) => {
    setCount(count + 0.08 * delta);

    setState({
      tileScale: {
        // x: 2 + Math.sin(count),
        x: 1,
        y: (60 + Math.cos(count))/60
      },
      tilePosition: {
        x: state.tilePosition.x + 0.4,
        y: state.tilePosition.y + 0.4
      }
    });
  });
  return (
    <TilingSprite
      x={x}
      y={y}
      image={image}
      width={width}
      height={height}
      tilePosition={isMove?state.tilePosition:0} //動く
      tileScale={isBounce?state.tileScale:1} // ぼよんぼよん
    />
  );
}