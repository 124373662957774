import React, { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text, useApp, useTick } from '@pixi/react';
// import * as PIXI from 'pixi.js';
import { Texture, TextStyle } from 'pixi.js';
import { easeOutBounceStrong, easeOutBounceWeak} from './PixiUtil';
import { getPlayerMasu } from '../util';

const ContainerDialog = ({...props}) => {
  const { G, ctx, playerID, x, y, width, height, children, screen_scale, map_scale, onClick, masu, speed=0.03 } = props;

  const [scale, setScale] = useState(0.1); // 初期スケールを小さく設定
  const [zoomState, setZoomState] = useState("in"); //in:ズームイン中, fin:ズームイン完了 ,out:ズームアウト中, fout:ズームアウト完了
  const [atime, setATime] = useState(0); // アニメーションの時間を管理するステート

  const containerRef = useRef(null);
  useTick((delta) => {
    if (zoomState === "in") {
      setATime((prevTime) => Math.min(prevTime + speed * delta, 1)); // 時間を更新
      const newScale = easeOutBounceWeak(atime); // イージング関数を用いてスケールを計算
      setScale(newScale);

      if (atime === 1) {
        setScale(1); //本来不要だが小数点誤差で1にならないことがあるため
        setZoomState("fin"); // アニメーション完了

        // // AIの場合は1秒後にズームアウト
        // if(G.gameStat.isAI && ctx.currentPlayer == "1"){
        //   setTimeout(()=>{setZoomState("out")}, 1500);
        // }
      }
    }else if(zoomState === "out"){
			// ズームアウト中
			setScale(prevScale => {
				const newScale = Math.max(prevScale - speed*2 * delta, 0.1);
				if ( newScale <= 0.1 ) {
          containerRef.current.destroy(true); //動画を止めるためにdestroy
 					// スケールが0.1に到達したらズームアウト完了
					setZoomState("fout");
          if(onClick)onClick();
				}
				return newScale;
			});
		}
  });


  return(
    zoomState !== "fout" &&
      <Container
        x={width / 2} 
        y={height / 2} 
        pivot={{ x: width / 2, y: height / 2 }}
        scale={scale}
        interactive={true} // インタラクティブに設定
        pointerdown={()=>(setZoomState("out"))	} // クリックでズームアウト
        visible={zoomState !== "fout"} // zoomState が "fout" の場合は非表示
        ref={containerRef}
      >
        {children}
      </Container>
  );
};

export { ContainerDialog };