// import Papa from 'papaparse'; // 盤面データ読み込み用csvパーサー
// const MASU_CSV_PATH = './data.csv'; // CSVファイルのパス

// 非同期制御や無駄なGへの送信が入るので、pythonで変換した静的データを使うこととする

/**
 * 盤面データ
 * csvファイルから読み込み、以下の型のオブジェクト配列に変換
 * ヘッダ名も同様である必要がある
[
    {
        id:0,
        description:"", //マスの文章,
        img:"", //マスの画像ファイル名
        effectType:"", //効果の種類
        effectValue:0, //効果の値
        x:0, //x座標
        y:0, //y座標
   },
 ]
 */
//  const initMasuData = async () => {
//     console.log("initMasuData");
//     // CSVファイルのパス
//     const csvFile = require(MASU_CSV_PATH);
//     console.log("csvFile");

//     const response = await fetch(csvFile);
//     const reader = response.body.getReader();
//     const result = await reader.read();
//     const decoder = new TextDecoder('utf-8');
//     const csv = decoder.decode(result.value);
//     console.log("csv",csv);

//     return new Promise((resolve) => {
//       Papa.parse(csv, {
//         complete: function(results) {
//           console.log("result",results.data);
//           // CSVの各行を所定のオブジェクト形式に変換
//           const data = results.data.map((item) => ({
//             id: parseInt(item.id, 10),
//             description: item.description,
//             img: item.img,
//             effectType: item.effectType,
//             effectValue: parseInt(item.effectValue, 10),
//             x: parseInt(item.x, 10),
//             y: parseInt(item.y, 10)
//           }));
//           resolve(data);
//         },
//         header: true
//       });
//     });
// };

const MASU_DATA = [
    {
        "id": 0,
        "title": "",
        "description": "スタート",
        "img": "masu_LARGE.png",
        "effectType": "move",
        "effectValue": 0,
        "eventImg": "",
        "movie": "",
        "movie": "https://enkakusample.sirkus.co.jp/enkaku.mp4",
        "x": 461.0,
        "y": 1032.0,
        "w": 224.0,
        "h": 144.0
    },
    {
        "id": 1,
        "title": "1シャンプーで",
        "description": "シャンプーを1番早く合格",
        "img": "masu_H_LR.png",
        // "effectType": "move",
        "effectType": "power",
        "effectValue": 1,
        "eventImg": "",
        "movie": "",
        "movie": "https://enkakusample.sirkus.co.jp/enkaku.mp4",
        "x": 639.0,
        "y": 1032.0,
        "w": 132.0,
        "h": 94.0
    },
    {
        "id": 2,
        "title": "2パーマで",
        "description": "カリキュラムのパーマができなくて先輩にビンタされる",
        "img": "masu_H_LR.png",
        // "effectType": "move",
        "effectType": "power",
        "effectValue": 1,
        "eventImg": "",
        "movie": "",
        "movie": "https://enkakusample.sirkus.co.jp/enkaku.mp4",
        "x": 771.0,
        "y": 1032.0,
        "w": 132.0,
        "h": 94.0
    },
    {
        "id": 3,
        "title": "3憧れの先輩",
        "description": "憧れの先輩(松永さん)のシャンプーボーイになりたくてルール違反しまくって松永さんのシャンプーボーイになる",
        "img": "masu_H_LT.png",
        // "effectType": "move",
        "effectType": "power",
        "effectValue": -1,
        "eventImg": "",
        "movie": "",
        "movie": "https://enkakusample.sirkus.co.jp/enkaku.mp4",
        "x": 906.0,
        "y": 1032.0,
        "w": 138.0,
        "h": 94.0
    },
    {
        "id": 4,
        "title": "4ルール違反",
        "description": "ルール違反しまくっていたら先輩に呼び出されて殴られる",
        "img": "masu_V_BL.png",
        // "effectType": "move",
        "effectType": "power",
        "effectValue": -1,
        "eventImg": "",
        "movie": "",
        "movie": "https://enkakusample.sirkus.co.jp/enkaku.mp4",
        "x": 928.0,
        "y": 922.0,
        "w": 94.0,
        "h": 138.0
    },
    {
        "id": 5,
        "title": "5指名が…",
        "description": "無理矢理でもメインアシスタントしていたら沢山のお客様にカラー指名、パーマ指名をもらう",
        "img": "masu_H_RL.png",
        "effectType": "money",
        "effectValue": 100,
        "eventImg": "",
        "movie": "",
        "movie": "https://enkakusample.sirkus.co.jp/enkaku.mp4",
        "x": 821.0,
        "y": 900.0,
        "w": 132.0,
        "h": 94.0
    },
    {
        "id": 6,
        "title": "6カット試験",
        "description": "カットの試験を着々と合格していく",
        "img": "masu_H_RL.png",
        "effectType": "money",
        "effectValue": 100,
        "eventImg": "eventimage_sample1.png",
        "movie": "",
        "x": 689.0,
        "y": 900.0,
        "w": 132.0,
        "h": 94.0
    },
    {
        "id": 7,
        "title": "7決算",
        "description": "決算",
        "img": "masu_H_RL.png",
        "effectType": "kessan",
        "effectValue": 0,
        "eventImg": "",
        "movie": "",
        "x": 557.0,
        "y": 900.0,
        "w": 132.0,
        "h": 94.0
    },
    {
        "id": 8,
        "title": "8店舗移動？",
        "description": "お客様からの指名が多かった為、毎年あるアシスタントの店舗移動を毎年免れる",
        "img": "masu_V_RT.png",
        "effectType": "money",
        "effectValue": 200,
        "eventImg": "eventimage_sample1.png",
        "movie": "",
        "x": 450.0,
        "y": 878.0,
        "w": 94.0,
        "h": 138.0
    },
    {
        "id": 9,
        "title": "9スタイリストデビュー？",
        "description": "最後の試験でトップだったのに店舗の都合でスタイリストデビュー見送り",
        "img": "masu_V_BT.png",
        "effectType": "money",
        "effectValue": -100,
        "eventImg": "eventimage_sample1.png",
        "movie": "",
        "x": 450.0,
        "y": 743.0,
        "w": 94.0,
        "h": 132.0
    },
    {
        "id": 10,
        "title": "10ブローで…",
        "description": "最後の試験に合格し川島先生のメインアシスタントになるが初日にブローを失敗してしばらく仕事させてもらえなくなる",
        "img": "masu_H_BR.png",
        "effectType": "money",
        "effectValue": -100,
        "eventImg": "eventimage_sample1.png",
        "movie": "",
        "x": 472.0,
        "y": 636.0,
        "w": 138.0,
        "h": 94.0
    },
    {
        "id": 11,
        "title": "11スタイリストデビュー？",
        "description": "川島先生にボロボロになるまで鍛え上げられて晴れてスタイリストデビューする",
        "img": "masu_H_LR.png",
        "effectType": "money",
        "effectValue": -100,
        "eventImg": "eventimage_sample1.png",
        "movie": "",
        "x": 607.0,
        "y": 636.0,
        "w": 132.0,
        "h": 94.0
    },
    {
        "id": 12,
        "title": "ゴール",
        "description": "ゴール！！",
        "img": "masu_LARGE.png",
        "effectType": "goal",
        "effectValue": 0,
        "eventImg": "goal.png",
        "movie": "",
        "x": 785.0,
        "y": 636.0,
        "w": 224.0,
        "h": 144.0
    },
    // {
    //     "id": 13,
    //     "title": "13",
    //     "description": "ああああああああああああああああああああああああああああああああああ",
    //     "img": "masu_V_TR.png",
    //     "effectType": "power",
    //     "effectValue": 3,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 785.0,
    //     "y": 777.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 14,
    //     "title": "14",
    //     "description": "いいいいいいいいいいいいいいいいいいいいいいいいいいいいいいいい",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": -1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 892.0,
    //     "y": 799.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 15,
    //     "title": "15",
    //     "description": "うううううううううううううううううううううううううううううう",
    //     "img": "masu_V_LT.png",
    //     "effectType": "power",
    //     "effectValue": -1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 999.0,
    //     "y": 777.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 16,
    //     "title": "16",
    //     "description": "えええええええええええええええええええええええ",
    //     "img": "masu_V_BT.png",
    //     "effectType": "power",
    //     "effectValue": -2,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 999.0,
    //     "y": 642.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 17,
    //     "title": "17",
    //     "description": "おおおおおおおおおおおおおおおおおおおおおおおおおおおおおお",
    //     "img": "masu_H_BR.png",
    //     "effectType": "power",
    //     "effectValue": -2,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1021.0,
    //     "y": 535.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 18,
    //     "title": "18",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": -3,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1149.0,
    //     "y": 535.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 19,
    //     "title": "19",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LB.png",
    //     "effectType": "power",
    //     "effectValue": 0,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1284.0,
    //     "y": 535.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 20,
    //     "title": "20",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1306.0,
    //     "y": 642.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 21,
    //     "title": "21",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1284.0,
    //     "y": 749.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 22,
    //     "title": "22",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1146.0,
    //     "y": 749.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 23,
    //     "title": "23",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1124.0,
    //     "y": 856.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 24,
    //     "title": "24",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_LARGE.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1173.0,
    //     "y": 992.0,
    //     "w": 224.0,
    //     "h": 144.0
    // },
    // {
    //     "id": 25,
    //     "title": "25",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1348.0,
    //     "y": 994.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 26,
    //     "title": "26",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1480.0,
    //     "y": 994.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 27,
    //     "title": "27",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_LT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1585.0,
    //     "y": 972.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 28,
    //     "title": "28",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_BR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1607.0,
    //     "y": 862.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 29,
    //     "title": "29",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_LB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1717.0,
    //     "y": 884.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 30,
    //     "title": "30",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1739.0,
    //     "y": 995.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 31,
    //     "title": "31",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1877.0,
    //     "y": 995.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 32,
    //     "title": "32",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1899.0,
    //     "y": 888.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 33,
    //     "title": "33",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1899.0,
    //     "y": 753.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 34,
    //     "title": "34",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1792.0,
    //     "y": 731.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 35,
    //     "title": "35",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1660.0,
    //     "y": 731.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 36,
    //     "title": "36",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_LARGE.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1482.0,
    //     "y": 731.0,
    //     "w": 224.0,
    //     "h": 144.0
    // },
    // {
    //     "id": 37,
    //     "title": "37",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1482.0,
    //     "y": 590.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 38,
    //     "title": "38",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1589.0,
    //     "y": 568.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 39,
    //     "title": "39",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1721.0,
    //     "y": 568.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 40,
    //     "title": "40",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_LT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1828.0,
    //     "y": 546.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 41,
    //     "title": "41",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1806.0,
    //     "y": 436.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 42,
    //     "title": "42",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1671.0,
    //     "y": 436.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 43,
    //     "title": "43",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1539.0,
    //     "y": 436.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 44,
    //     "title": "44",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1404.0,
    //     "y": 436.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 45,
    //     "title": "45",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1382.0,
    //     "y": 326.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 46,
    //     "title": "46",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1489.0,
    //     "y": 304.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 47,
    //     "title": "47",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1621.0,
    //     "y": 304.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 48,
    //     "title": "48",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_LARGE.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1799.0,
    //     "y": 304.0,
    //     "w": 224.0,
    //     "h": 144.0
    // },
    // {
    //     "id": 49,
    //     "title": "49",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1799.0,
    //     "y": 166.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 50,
    //     "title": "50",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1777.0,
    //     "y": 59.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 51,
    //     "title": "51",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1639.0,
    //     "y": 59.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 52,
    //     "title": "52",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1617.0,
    //     "y": 169.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 53,
    //     "title": "53",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1507.0,
    //     "y": 191.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 54,
    //     "title": "54",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1485.0,
    //     "y": 81.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 55,
    //     "title": "55",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1378.0,
    //     "y": 59.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 56,
    //     "title": "56",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1246.0,
    //     "y": 59.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 57,
    //     "title": "57",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1114.0,
    //     "y": 59.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 58,
    //     "title": "58",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_RB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1007.0,
    //     "y": 81.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 59,
    //     "title": "59",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1029.0,
    //     "y": 191.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 60,
    //     "title": "60",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_LARGE.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1210.0,
    //     "y": 191.0,
    //     "w": 224.0,
    //     "h": 144.0
    // },
    // {
    //     "id": 61,
    //     "title": "61",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1210.0,
    //     "y": 332.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 62,
    //     "title": "62",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 1103.0,
    //     "y": 354.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 63,
    //     "title": "63",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 971.0,
    //     "y": 354.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 64,
    //     "title": "64",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_RT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 864.0,
    //     "y": 332.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 65,
    //     "title": "65",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 864.0,
    //     "y": 197.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 66,
    //     "title": "66",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 864.0,
    //     "y": 62.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 67,
    //     "title": "67",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 757.0,
    //     "y": 40.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 68,
    //     "title": "68",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_RB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 650.0,
    //     "y": 62.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 69,
    //     "title": "69",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 628.0,
    //     "y": 172.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 70,
    //     "title": "70",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_RT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 518.0,
    //     "y": 150.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 71,
    //     "title": "71",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 496.0,
    //     "y": 40.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 72,
    //     "title": "72",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_LARGE.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 315.0,
    //     "y": 40.0,
    //     "w": 224.0,
    //     "h": 144.0
    // },
    // {
    //     "id": 73,
    //     "title": "73",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 137.0,
    //     "y": 40.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 74,
    //     "title": "74",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_RB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 30.0,
    //     "y": 62.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 75,
    //     "title": "75",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 52.0,
    //     "y": 172.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 76,
    //     "title": "76",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 187.0,
    //     "y": 172.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 77,
    //     "title": "77",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_LB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 294.0,
    //     "y": 194.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 78,
    //     "title": "78",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 316.0,
    //     "y": 304.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 79,
    //     "title": "79",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 451.0,
    //     "y": 304.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 80,
    //     "title": "80",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 583.0,
    //     "y": 304.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 81,
    //     "title": "81",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_LB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 690.0,
    //     "y": 326.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 82,
    //     "title": "82",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 668.0,
    //     "y": 436.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 83,
    //     "title": "83",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 533.0,
    //     "y": 436.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 84,
    //     "title": "84",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_LARGE.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 355.0,
    //     "y": 436.0,
    //     "w": 224.0,
    //     "h": 144.0
    // },
    // {
    //     "id": 85,
    //     "title": "85",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 174.0,
    //     "y": 436.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 86,
    //     "title": "86",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_BL.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 152.0,
    //     "y": 326.0,
    //     "w": 94.0,
    //     "h": 138.0
    // },
    // {
    //     "id": 87,
    //     "title": "87",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_RB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 42.0,
    //     "y": 304.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 88,
    //     "title": "88",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 20.0,
    //     "y": 411.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 89,
    //     "title": "89",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 20.0,
    //     "y": 543.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 90,
    //     "title": "90",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 20.0,
    //     "y": 675.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 91,
    //     "title": "91",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 20.0,
    //     "y": 807.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 92,
    //     "title": "92",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_TB.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 20.0,
    //     "y": 939.0,
    //     "w": 94.0,
    //     "h": 132.0
    // },
    // {
    //     "id": 93,
    //     "title": "93",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_TR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 42.0,
    //     "y": 1046.0,
    //     "w": 138.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 94,
    //     "title": "94",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_H_LR.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 177.0,
    //     "y": 1046.0,
    //     "w": 132.0,
    //     "h": 94.0
    // },
    // {
    //     "id": 95,
    //     "title": "95",
    //     "description": "んんんんんんんんんんんんんんんんんんんんん",
    //     "img": "masu_V_LT.png",
    //     "effectType": "power",
    //     "effectValue": 1,
    //     "eventImg": "",
    //     "movie": "",
    //     "x": 284.0,
    //     "y": 1024.0,
    //     "w": 94.0,
    //     "h": 138.0
    // }
]

export {MASU_DATA}