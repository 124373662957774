import React from 'react';
import { Sprite, withFilters, Container, Text } from '@pixi/react';
import * as PIXI from 'pixi.js';

export const ContainerMasuMoji = ({...props}) =>{
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage, x, y, screen_scale, masu, onClick } = props;
  const player = G.players[ctx.currentPlayer]
  // 既に訪れたマスかどうか
  // const isVisit = G.players[ctx.currentPlayer].positionDisplay >= masu.id;
  // visitMasu[]に含まれているかどうか
  const isVisit = G.gameStat.visitMasu.includes(masu.id);
  // フェーズがdiceかつマスの位置がプレイヤーの位置より後ろの場合クリック可能。ゲームクリア後もクリック可能
  const canInteract = masu.effectType !== "kessan" 
                      && 
                      (
                        (G.gameStat.diceStep == "dice"  && isVisit)
                        || (ctx.gameover != undefined && masu.id !=0)
                      );

	const fontSize = 20 * screen_scale;
	const fontStyle = {
		fontFamily: 'Arial',
		fontSize: fontSize,
		fill: 0x000000,
		align: 'center',
		stroke: 0xffffff,
		strokeThickness: 5,
		lineJoin: 'round',
	};
	const fontStyleMada = {
		fontFamily: 'Arial',
		fontSize: fontSize,
		fill: 0x333333,
		align: 'center',
		stroke: 0xaaaaaa,
		strokeThickness: 5,
		lineJoin: 'round',
	};
  // マスのタイトルを5文字ごとに\nで改行
  const title = masu.title ? masu.title.match(/.{1,5}/g).join("\n") : "";

  return (
    <Container
      x={(masu.x)*screen_scale}
      y={(masu.y)*screen_scale}
      eventMode={"none"} //クリックイベントを下に流す
      >
      <Text
          text={title}
          anchor={0.5}
          style={canInteract?fontStyle:fontStyleMada}
        />
    </Container>
  );
};