/**
 * ゲーム状態データ
 */
const initGameStat = () => {
  return {
    isAI:false,//AI戦かどうか
    sleepStart:0,//AI処理用　スリープ開始時間
    sleepMsec:0,//AI処理用　スリープ時間
    sleepNextFunc:"",//AI処理用　次の処理
    diceStep:"",//"":初期状態 "dice":サイコロを振れる状態 "step":移動状態 "kessan":決算処理 "event":イベントを実行する状態 "end":ターン終了待ち状態
    cpPower:3,//企業力
    visitMasu: [],//訪れたマス
    tmp:{}

  }
}

const initPlayerData = (playerID) => {
  return {
    id: playerID,
    dice: 0,
    money: 1500,
    kabu:0,
    position: 0,
    positionDisplay: 0,//画面表示上の位置
    tmp:{},
    goal: false,
    goalOrder: -1,
    cpPower:3, //企業力
  }
}




export {initGameStat, initPlayerData}