// ContainerGoal.jsx
import React, { useState } from 'react';
import { Sprite, Container, useTick,Text } from '@pixi/react';
import { easeOutBounceStrong } from './PixiUtil';
import { ContainerDialog } from './ContainerDialog';

const ContainerKessan = ({ ...props }) => {
  const { G, ctx,playerID, x, y, width, height, children, screen_scale, map_scale, onClick, masu } = props;
  const fontSize = 48 * screen_scale;
  const player = G.players[ctx.currentPlayer];

  return (
    <ContainerDialog
      {...props}
    >
      <Sprite //決算背景
        image={"image/kessan.png"} 
        width={width}
        height={height}
        x={0}
        y={0}
        />
      <Text text={`企業力${player.cpPower}\n${player.cpPower*100}を取得した`}
          x={width/2}
          y={height/2}
          anchor={0.5}
          style={{fill: 0x000000, fontSize: fontSize}}/>
    </ContainerDialog>
  );
};

export { ContainerKessan };
