import React, { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text, useApp, useTick } from '@pixi/react';

const ContainerZoomButton = ({...props}) => {
	const { G, ctx, moves, playerID, matchData, x, y, width, height, children, screen_scale, map_scale, onMapZoom } = props;

	const clickZoom = () => {
		if(onMapZoom)onMapZoom();
	}

	const spr_w = 215*screen_scale;
	const spr_h = 75*screen_scale;
	return(
		<Container
			x={x}
			y={y}
			>
			{ 
				<Sprite
					image="image/mapzoom.png" // 画像のURL
					x={0}
					y={0}
					// anchor={0.5}
					width={spr_w} // 幅
					height={spr_h} // 高さ
					interactive={true}
					cursor='pointer'
					pointerdown={clickZoom}
					// //マウスフォーカス時に画像効果を変更
					pointerover={(e)=>{e.target.width = spr_w*1.1;e.target.height = spr_h*1.1;}}
					pointerout={(e)=>{e.target.width = spr_w;e.target.height = spr_h;}}
					/>
			}
		</Container>
	);
};

export { ContainerZoomButton };