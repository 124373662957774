import { INVALID_MOVE } from 'boardgame.io/core';
import { MASU_DATA } from './DataMasu';
import { DEBUG } from '../../setting';
import { getGoalMoney } from '../util';

const rollDice = ({ G, random, ctx, events, playerID }) => {
  if(G.gameStat.diceStep !== "dice"){
    console.log("rollDice diceStep is",G.gameStat.diceStep);
    return INVALID_MOVE;
  }
  const player = G.players[playerID];

  // const dice = DEBUG? 2 : random.D6();
  const dice = random.D6();
  player.dice = dice;

  if(player.position + dice >= MASU_DATA.length - 1){
    player.position = MASU_DATA.length - 1;
  }else{
    player.position += dice;
  }

  //マスのデータのイベントを実行
  const masu = MASU_DATA[player.position];
  //効果の種類によって処理を分ける
  switch (masu.effectType) {
    case "move":
      player.tmp.position = player.position + masu.effectValue;
      if (player.tmp.position < 0) {
        player.tmp.position = 0;
      }
      break;
    case "money":
      player.tmp.money = player.money + masu.effectValue;
      // マイナスを許容するためコメントアウト
      // if (player.tmp.money < 0) {
      //   player.tmp.money = 0;
      // }
      break;
    case "power":
      player.tmp.cpPower = masu.effectValue;
      break;
    case "goal":
      //何番目のゴールか？全プレイヤーのゴールフラグの数を数える
      const goalNum = G.players.filter((p) => p.goal).length;
      // 順位に応じて獲得金額を変更
      player.goalOrder = goalNum+1;
      player.tmp.money = player.money + getGoalMoney(player.goalOrder,player.cpPower);
    default:
      break;
  }
  G.gameStat.diceStep = "donedice";

  // BoardGame.ioはUIに合わせてAIの行動タイミングをトリガできないため
  // AIの処理をプレイヤーが代替で行うための特殊処理
  if(G.gameStat.isAI && playerID ==1){
    events.setActivePlayers({   value: {'0': 'aiStage' }});
  }
};

const finishDice = ({ G, ctx, playerID }) => {
  const player = G.gameStat.isAI && ctx.currentPlayer==1 ? G.players[1] : G.players[playerID];

  if(G.gameStat.diceStep === "donedice"){
    G.gameStat.diceStep = "step";
    // player.positionDisplay += 1;//最初の一歩
    // // 決算マスを通過した場合は決算ステップに移行
    // if(MASU_DATA[player.positionDisplay].effectType === "kessan"){
    //   G.gameStat.diceStep = "kessan";
    //   return;
    // }
  }
};

// 画面表示用のマス移動処理
const stepMasu = ({G, ctx, playerID}) => {
  if(G.gameStat.diceStep !== "step"
    && G.gameStat.diceStep !== "end"/* マスの効果で動くとき */){
    return INVALID_MOVE;
  }
  const player = G.gameStat.isAI && ctx.currentPlayer==1 ? G.players[1] : G.players[playerID];


  // 表示上の見た目と実際の位置が一致したら無効
  if(player.positionDisplay == player.position){
    return INVALID_MOVE;
  }else if(player.positionDisplay > player.position){
    player.positionDisplay -= 1;
  }else if(player.positionDisplay < player.position){
    player.positionDisplay += 1;
    // 決算マスを通過した場合は決算ステップに移行
    if(MASU_DATA[player.positionDisplay].effectType === "kessan"){
      G.gameStat.diceStep = "kessan";
      return;
    }
  }
};
const finishKessan = ({ G, ctx, playerID }) => {
  const player = G.gameStat.isAI && ctx.currentPlayer==1 ? G.players[1] : G.players[playerID];

  if(G.gameStat.diceStep === "kessan"){

    player.money += player.cpPower * 10; //企業力のに応じてお金付与

    // 訪れたマスを記録
    if(!G.gameStat.visitMasu.includes(player.positionDisplay)){
      G.gameStat.visitMasu.push(player.positionDisplay);
    }

    // まだ移動が可能な場合
    if(player.positionDisplay < player.position){
      G.gameStat.diceStep = "step";
    }else{ //ちょうど決算マスにとまった場合
      G.gameStat.diceStep = "end";
    }
  }else{
    return INVALID_MOVE;
  }
}

const finishStep = ({G}) => {
  if(G.gameStat.diceStep === "step"){
    G.gameStat.diceStep = "event";
  }
};

const doEvent = ({ G, ctx, playerID }) => {
  if(G.gameStat.diceStep !== "event"){
    console.log("doEvent diceStep is",G.gameStat.diceStep);
    return INVALID_MOVE;
  }
  const player = G.gameStat.isAI && ctx.currentPlayer==1 ? G.players[1] : G.players[playerID];

  // マスの移動効果を実行する前に訪れたマスを記録
  if(!G.gameStat.visitMasu.includes(player.position)){
    G.gameStat.visitMasu.push(player.position);
  }


  if(player.tmp.position)player.position = player.tmp.position;
  if(player.tmp.money)player.money = player.tmp.money;
  if(player.tmp.cpPower){
    player.cpPower += player.tmp.cpPower;
    if(player.cpPower < 0){
      player.cpPower = 0;
    }
  }



  player.tmp = {};
  G.gameStat.tmp = {};
  G.gameStat.diceStep = "end";
};

const done = ({G, ctx, events,playerID}) => {
  const player = G.gameStat.isAI && ctx.currentPlayer==1 ? G.players[1] : G.players[playerID];

  // if(G.gameStat.isAI && G.gameStat.nowAI && playerID === 0){ //AIの代替処理中
  //   G.gameStat.nowAI = false; //AIの代替処理終了
  //   G.players[1].dice = 0; //AIのサイコロを初期化
  //   events.endTurn(0); //再度プレイヤーのターン
  //   return;
  // }else if(G.gameStat.isAI && G.gameStat.nowAI && playerID === 1){
  //   // AIのターンが終わるが、プレイヤーが代替処理を行うので初期化しない
  //   events.endTurn();
  //   return;
  // }

  //ゴールしてたらゴールフラグ立てる
  if(player.positionDisplay>= MASU_DATA.length - 1){
    player.goal = true;
  }

  player.dice = 0; //通常処理　初期化

  //ゴールしていない次のプレイヤーに回す
  let nextPlayerID = player.id;
  //全員ゴールしていると永久ループになるのでスキップ
  if(G.players.filter((p) => p.goal).length < G.players.length){
    while(true){
      nextPlayerID = (nextPlayerID + 1) % ctx.numPlayers;
      if(!G.players[nextPlayerID].goal){
        break;
      }
    }
  }
  events.endTurn({next:nextPlayerID});

}

export { rollDice, finishDice, stepMasu, finishStep, finishKessan,doEvent,done }