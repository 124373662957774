import React, { useEffect, useRef } from 'react';
import { Sprite, Container, Text, useApp, useTick } from '@pixi/react';
import { Texture } from 'pixi.js';
import { lerp } from './PixiUtil';
import { getPlayerMasu } from '../util';

const ContainerOpening = ({...props}) => {
  const { G, playerID, x, y, width, height, children, screen_scale, map_scale,onClick } = props;

	// クリック時にα値を変更
	const [alpha, setAlpha] = React.useState(1);
	// クリック時に上にスライド
	const [conY, setY] = React.useState(0);
	const [transition, setTransition] = React.useState(false);

	useTick((delta) => {
		// クリック時にα値を変更
		if(transition){
			setAlpha(prevAlpha => Math.max(prevAlpha - 0.01 * delta, 0)); // 前のalpha値に基づいて更新
			setY(prevY => prevY - 10 * delta); // 前のalpha値に基づいて更新
			if(conY <= -height){
				setTransition(false);
				onClick();
			}
		}
	});

  return(
		<Container
			x={0}
			y={conY}
			alpha={alpha}
		>
			<Sprite
				image="image/opening.png" // 画像のURL
				width={width} // 幅
				height={height} // 高さ
				x={0}
				y={0}
				//クリックイベント
				interactive={true} // コンテナをインタラクティブに設定
				pointerdown={()=>(setTransition(true))} // ドラッグ開始時のイベント
			/>
		</Container>
  );
};

export { ContainerOpening };