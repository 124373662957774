import React, { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text, useApp, useTick } from '@pixi/react';
import { Texture } from 'pixi.js';
import { lerp } from './PixiUtil';
import { getPlayerMasu } from '../util';
import { ContainerPlayerStat } from './ContainerPlayerStat';
import { ContainerActionDice } from './ContainerActionDice';
import { ContainerZoomButton } from './ContainerZoomButton';
import { ContainerCorporateStat } from './ContainerCorporateStat';

const ContainerControl = ({...props}) => {
  const { G, ctx, playerID, matchData, x, y, width, height, children, screen_scale, map_scale, onClick, isZoom } = props;

  return(
      <Container
        x={0} 
        y={0} 
      >
        {isZoom && (
        <ContainerPlayerStat
            {...props}
            x={0}
            y={0}
            width={width}
            height={height}
            />
        )}
        {isZoom && (
        <ContainerActionDice
            {...props}
            x={0}
            y={0}
            width={width-80*screen_scale}
            height={height}
            />
        )}
        <ContainerZoomButton
            {...props}
            x={30*screen_scale}
            y={448*screen_scale}
            />
        {/* {isZoom && (
        <ContainerCorporateStat
            {...props}
            x={width-80*screen_scale}
            y={0}
            />
        )} */}
      </Container>
  );
};

export { ContainerControl };