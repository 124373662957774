import React from "react";

const getScreenSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight
});

const calcAspectRatio = (size, baseSize) => {
  const aspectRatio = baseSize.width / baseSize.height;
  //横を基準にアスペクト比を維持した高さを計算。最大はbaseSize.width
  const width = size.width > baseSize.width ? baseSize.width : size.width;
  const height = width / aspectRatio;
  return { width:width, height:height };
}


const useResize = (baseSize, size, setSize) => {
  const onWindowResize = () => {
    const newSize = calcAspectRatio(getScreenSize(), baseSize);
    setSize(newSize);
  };

  React.useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);
};

const lerp = (start, end, t) => {
  return start * (1 - t) + end * t;
};

// easeOutBounce イージング関数の実装
const easeOutBounceStrong = (t)=>{
  const n1 = 10;
  const d1 = 2.5;

  return easeOutBounce(t, n1, d1);
}
const easeOutBounceWeak = (t)=>{
  const n1 = 5;
  const d1 = 2.5;

  return easeOutBounce(t, n1, d1);
}

const easeOutBounce = (t, n1, d1) => {

  if (t < 1 / d1) {
    return n1 * t * t;
  } else if (t < 2 / d1) {
    return n1 * (t -= 1.5 / d1) * t + 0.75;
  } else if (t < 2.5 / d1) {
    return n1 * (t -= 2.25 / d1) * t + 0.9375;
  } else {
    return n1 * (t -= 2.625 / d1) * t + 0.984375;
  }
};


export { useResize, getScreenSize, lerp, easeOutBounce, easeOutBounceStrong, easeOutBounceWeak, calcAspectRatio}