import React, { useEffect } from 'react';
import { Client } from 'boardgame.io/react';
import { SugorokuGame } from './game/SugorokuGame';
import { SocketIO } from 'boardgame.io/multiplayer';
import { PixiMain } from './view/PixiMain';
import { MULTI_GAME } from '../setting';

import { RandomBot,MCTSBot } from 'boardgame.io/ai';
import { Local } from 'boardgame.io/multiplayer'

const SugorokuBoard = ({ ...props }) => {
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage } = props;
  return (
    <div style={
      {
        //センタリング
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

      }
    }>
      <PixiMain {...props}/>
    </div>
  );
};

const bots = { '1': RandomBot }
const SugorokuClient = Client({
  game: SugorokuGame,
  board: SugorokuBoard,
  numPlayers: 2,
  multiplayer:Local({ bots }),
});

export { SugorokuClient, SugorokuBoard };
