import React, { useState } from 'react';
import { Sprite, Container, useTick } from '@pixi/react';
import { ContainerDialog } from './ContainerDialog';

const ContainerRoulette = ({ ...props }) => {
  const { G, ctx, moves, playerID, matchData, x, y, width, height, children, screen_scale, targetNumber, onStop, onClick } = props;

  // 針の回転角度（ラジアンで管理）
  const [rotation, setRotation] = useState(0);
  // 回転速度（ラジアン/フレーム） 初期値のRandomは止まり方が変わるように 0.5+randomつまり0.5~1.0の間の倍率
  const [speed, setSpeed] = useState(Math.PI * (0.5 + Math.random()/8) / 4);
  // ルーレットが回転中かどうか
  const [isSpinning, setIsSpinning] = useState(true);

  // 指定された数字の位置に近づいたら停止処理
  const section = 2 * Math.PI/ 6; // ルーレットの1セクションの角度（ラジアン）
  // 1の角度 1が9時(270度)になるように調整
  const baseAngle = 2*Math.PI * (3/4); // 3/4π rad = 270°
  const targetAngle = - (section * (targetNumber - 1)  + baseAngle); // プレートを回すようにしたので逆方向の為-1をかける
  

  useTick((delta) => {
    if (isSpinning) {
      // 回転速度（speed）で回転角度を更新
      setRotation(rotation => rotation + speed * delta);

      // 速度を徐々に減少させる
      setSpeed(speed => Math.max(speed - 0.005 * delta, 0.03)); // 速度が0にならないように制御

      // 速度が小さくなった時点で、目標角度に近づいたら停止
      if (Math.abs(rotation - targetAngle) % (2 * Math.PI) < 0.1 && speed <= 0.2) {
        setIsSpinning(false); // 回転を停止
        setRotation(targetAngle); // 針を目標角度に合わせる
        onStop();//停止時コールバック
      }
    }
  });

  return (
    <ContainerDialog
      {...props}
      speed={0.04}
    >
      <Container
          x={x}
          y={y}
          interactive={true}
          cursor={!isSpinning?"pointer":null}
          pointerdown={()=>{if(!isSpinning)onClick()}} //止まった時のみクリック受付
      >
        {/* ルーレット（数字版画像） */}
        <Sprite image={"image/roulette_plate.png"} anchor={ 0.5 } width={width} height={height} rotation={rotation} />
        {/* 針画像 */}        
        <Sprite image={"image/roulette_needle.png"} anchor={ 0.5 } width={width} height={height}/>
      </Container>
    </ContainerDialog>
  );
};

export default ContainerRoulette;
