import { 
  BlurFilter,
  BaseTexture,
  SCALE_MODES,
} from 'pixi.js';
import {
  Sprite,
  Stage,
  Text,
  Container,
  useTick
  } from '@pixi/react';
import { useResize, calcAspectRatio, getScreenSize } from './PixiUtil';
import { ContainerMap } from './ContainerMap';
import { ContainerEvent } from './ContainerEvent';
import { SpriteBackground } from './SpriteBackground';
import React from 'react';

import { getPlayerMasu, getIdxMasu } from '../util';
import { ContainerOpening } from './ContainerOpening';
import { ContainerGoal } from './ContainerGoal';
import { ContainerControl } from './ContainerControl';
import { ContainerKessan } from './ContainerKessan';

export const PixiMain = ({...props}) =>{
  const { G, playerID, ctx, moves, matchData, playerName, sendChatMessage } = props;

  const baseSize = { width: 960, height: 540 };//基本サイズ
  const [size, setSize] = React.useState(calcAspectRatio(getScreenSize(), baseSize));

  useResize(baseSize, size, setSize); //画面に合わせたサイズ
  const scale = size.width / baseSize.width; //画面サイズに合わせた拡大率

  const [state, setState] = React.useState("opening");//opening,playing,ending

  const [clickMasu, setClickMasu] = React.useState(null);//クリックしたマス

  const [isZoom, setIsZoom] = React.useState(false);//ズーム中かどうか

  /* オープニング画面用処理 */
  const clickOpening = () => {
    setState("playing");
    setIsZoom(true);
  }
  //マップズームトグル
  const clickZoom = () => {
    setIsZoom(!isZoom);
  }


  return (
    <Stage {...size} id={size.width} options={{ backgroundColor: 0x333333, antialias: true }}>
      {/* 背景 */}
      <SpriteBackground
        {...props}
        image="image/bg.png"
        isMove={true}
        isBounce={true}
        width={size.width}
        height={size.height}   
        />
      { //state === "playing"&&
        (
        <React.Fragment>
        {/* マップ */}
        <ContainerMap
          {...props}
          // x={-masu.x *scale + size.width/2}
          // y={-masu.y *scale + size.height/2}
          width={size.width}
          height={size.height}
          screen_scale={scale}
          isZoom={isZoom}
          masuClick={(id)=>setClickMasu(id)}
        />

        {/* コントロールパネル */}
        <ContainerControl
          {...props}
          x={0}
          y={0}
          width={size.width}
          height={size.height}
          screen_scale={scale}
          onMapZoom={clickZoom}
          isZoom={isZoom}
        />

        {/* イベント止まったとき */
          ( getPlayerMasu(G, ctx.currentPlayer) != undefined && G.gameStat.diceStep === "event"
            // &&(
            //   !G.gameStat.isAI || // AIでない場合は常に表示
            //   G.gameStat.isAI && ctx.currentPlayer == "0" // AIの場合は自分のターンのみ表示
            // )
          )&&
          (
            <ContainerEvent
              {...props}
              x={0}
              y={0}
              key={getPlayerMasu(G, ctx.currentPlayer).id}
              // key={crypto.randomUUID()}
              masu={getPlayerMasu(G, ctx.currentPlayer)}
              width={size.width}
              height={size.height}
              screen_scale={scale}
              onClick={()=>moves.doEvent()}
            />
          )
        }

        {/* 決算になった */
          ( getPlayerMasu(G, ctx.currentPlayer) != undefined && G.gameStat.diceStep === "kessan" 
            // && (
            //   !G.gameStat.isAI || // AIでない場合は常に表示
            //   G.gameStat.isAI && ctx.currentPlayer == "0" // AIの場合は自分のターンのみ表示
            // )
          )&&
          (
            <ContainerKessan
              {...props}
              x={0}
              y={0}
              key={getPlayerMasu(G, ctx.currentPlayer).id}
              // key={crypto.randomUUID()}
              masu={getPlayerMasu(G, ctx.currentPlayer)}
              width={size.width}
              height={size.height}
              screen_scale={scale}
              onClick={()=>moves.finishKessan()}
            />
          )
        }
        {/* イベントマスクリック時 サイコロ振る前しかできない */
          ( (ctx.gameover || G.gameStat.diceStep === "dice") && // ゲームオーバー時またはサイコロ振る前のみ表示
           clickMasu 
          //  && (
          //   !G.gameStat.isAI || // AIでない場合は常に表示
          //   G.gameStat.isAI && ctx.currentPlayer == "0" // AIの場合は自分のターンのみ表示
          //   )
          )&&
          (
            <ContainerEvent
              {...props}
              x={0}
              y={0}
              key={clickMasu}
              // key={crypto.randomUUID()}
              masu={getIdxMasu(clickMasu)}
              width={size.width}
              height={size.height}
              screen_scale={scale}
              onClick={()=>{setClickMasu(null);moves.doEvent()}}//イベント確認コールバック click状態を
            />
          )
        }
        { /*ゴール */
          ctx.gameover && (
            <ContainerGoal
              image="image/end.png"
              width={size.width}
              height={size.height} 
              x={0}
              y={0}
            />
          )
        }
        </React.Fragment>
      )}
      { state === "opening" && (
        <ContainerOpening
          {...props}
          x={0}
          y={0}
          width={size.width}
          height={size.height}
          onClick={clickOpening}
        />
      )}

    </Stage>
  );
};